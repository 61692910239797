<template>
  <div
      v-click-outside="
      () => {
        searchStore.hideSearchSuggestions();
      }
    "
  >
    <!-- Search Query Input -->
    <input
        type="text"
        class="text-xs md:text-sm h-10 w-full rounded-l bg-white border border-a-neutral-dim border-r-0 p-2 focus:outline-none font-semibold"
        placeholder="Search Islamic Centers..."
        v-model="localSearchQuery"
        @input="getInstantSearchResults"
    />
    <!-- Search Query Input Ends -->
    <!-- Search Suggestions -->
    <div
        :class="[
        'flex flex-col w-full absolute top-10 bg-white border-b-2 border-l-2 border-r-2 rounded-b shadow-xl z-40',
      ]"
        v-if="viewSearchSuggestionsList"
    >
      <BaseInstantSearchSuggestionsList
          v-if="
          islamicCenterSearchSuggestions.length >
          0
        "
          :suggestions="
          islamicCenterSearchSuggestions
        "
          highlighting-field-name="name"
          list-title="Islamic Center Listings"
          :current-search-query="
          localSearchQuery
        "
          @suggestionSelected="
          (selectedListingSuggestion) =>
            gotoSuggestedIslamicCenterPage(
              selectedListingSuggestion
            )
        "
      />
    </div>
    <!-- Search Suggestions Div Ends -->
  </div>
</template>

<script setup>
const searchStore = useSearch();
const route = useRoute();

const localSearchQuery = ref("");
const islamicCenterSearchSuggestions =
    ref([]);

const viewSearchSuggestionsList =
    computed(() => {
      return (
          searchStore.is_showing_search_suggestions &&
          (
              islamicCenterSearchSuggestions
                  .value.length > 0)
      );
    });

watch(localSearchQuery, (newValue) => {
  if (localSearchQuery.value === "") {
    searchStore.hideSearchSuggestions();
  }
  searchStore.setSearchQuery(newValue);
});

watch(
    () => route.query,
    () => {
      localSearchQuery.value =
          searchStore.current_search_query;
    }
);

async function getInstantSearchResults() {
  if (
      localSearchQuery.value !== "" &&
      localSearchQuery.value.length > 1
  ) {
    searchStore.showSearchSuggestions();
    const results = await useMainIslamicCentersDataManager().getIslamicCenterInstantSearchResults(
        localSearchQuery.value
    );
    if (results) {
      console.log(results.islamic_center_results)
      islamicCenterSearchSuggestions.value =
          results.islamic_centers_results;
    }
  }
}

function gotoSuggestedIslamicCenterPage(islamicCenter) {
  localSearchQuery.value = "";
  navigateTo({
    name: "islamic-centers-slug",
    params: {
      slug: islamicCenter.slug,
    },
  });
}

onMounted(() => {
  if (
      searchStore.current_search_query
  ) {
    localSearchQuery.value =
        searchStore.current_search_query;
  } else {
    route.query.q != null
        ? (localSearchQuery.value =
            route.query.q)
        : (localSearchQuery.value =
            route.query.c);
  }
})
</script>

<style></style>
