<template>
  <div
      class="flex flex-col justify-center items-center w-full m-0 p-0 z-30 sticky top-0 bg-transparent shadow"
  >
    <!-- Top Bar -->
    <div class="flex flex-none w-full h-20 pt-2 px-0 md:px-2 lg:px-6 bg-white">
      <div
          class="flex items-center justify-evenly w-full max-w-screen-2xl mx-auto pt-2"
      >
        <!-- Brand Starts -->
        <nuxt-link
            :to="{ path: '/islamic-centers' }"
            class="flex flex-col w-1/12 pl-2 items-start justify-center"
        >
          <NuxtImg src="/images/main/logo.png" alt="" />
          <p class="text-xs mx-auto font-semibold tracking-widest">
            Islamic Centers
          </p>
        </nuxt-link>
        <!-- Brand Ends-->
        <!-- Buttons Section -->
        <nuxt-link
            :to="{ name: 'index' }"
            class="flex flex-row items-baseline px-4 font-semibold hover:text-a-secondary focus:outline-none"
        >
          Home
        </nuxt-link>
        <!-- Buttons Section Ends -->
        <!-- Search Section -->
        <div class="flex flex-row w-7/12 py-2 px-4 items-center justify-center">
          <!-- Search Bar -->
          <IslamicCentersInstantSearch />
          <!-- Search Bar Ends -->
        </div>
        <!-- Search Section Ends -->
        <!-- Right Extra Section -->
        <div class="flex flex-row-reverse w-3/12 items-center justify-start">
          <!-- User Dropdown -->
          <BaseUserDropdown v-if="isLoggedIn" />
          <!-- User Dropdown Ends -->
          <div class="flex flex-row items-center justify-center" v-else>
            <nuxt-link
                :to="{ name: 'login' }"
                class="mx-2 px-2 hover:text-a-secondary font-semibold"
            >
              Login
            </nuxt-link>
            <span class="text-2xl">/</span>
            <nuxt-link
                :to="{ name: 'register' }"
                class="mx-2 px-2 hover:text-a-secondary font-semibold"
            >
              Register
            </nuxt-link>
          </div>
          <BaseButton
              is-outlined
              margin-classes="mx-2"
              primary-color-class="a-primary"
              text-size-class="text-sm"
              width-class="w-auto"
              hovered-text-color-class="a-neutral-darkest"
              @click.native="gotoCreateNewIslamicCenterPage"
          >
            <Icon name="mdi:plus" />
            List Islamic Center
          </BaseButton>
        </div>
        <!-- Right Extra Section Ends -->
      </div>
    </div>
    <!-- Top Bar Ends-->
    <!-- Main Islamic Centers Alert -->
    <BaseAlert alignText="center" class="z-40" />
    <!-- Main Islamic Centers Alert Ends -->
  </div>
</template>

<script setup lang="ts">
function gotoCreateNewIslamicCenterPage() {
  navigateTo({
    name: "dashboard-islamic-centers-create-new",
  });
}
const isLoggedIn = computed(()=>{
  return useAuthManager().isLoggedIn.value
});
</script>

<style></style>
